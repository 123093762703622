<template>
  <div>
    <div
      class="page-header panel"
      style="display: grid; grid-template-columns: 12fr 1fr"
    >
      <h3 class="title">
        Санал болгох тохиргоо
      </h3>
      <div class="action-section">
        <router-link to="/suggest-log">
          <el-button type="success" size="medium">Log</el-button>
        </router-link>
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <el-table
            :data="driverConfigList"
            size="mini"
            style="width: 95%,margin: 100vh;"
          >
            <el-table-column prop="rider_type" label="Төрөл"></el-table-column>
            <el-table-column prop="size" label="Хэмжээ"></el-table-column>
            <!-- <el-table-column prop="service" label="Категори">
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  v-for="(service, index) in scope.row.service"
                  :key="index"
                >
                  {{ service }}
                </el-tag>
              </template>
            </el-table-column> -->
            <el-table-column prop="radius" label="радиус"></el-table-column>
            <el-table-column label="Дэлгэрэнгүй">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  style="margin-top: 10px;"
                  @click="suggestDetail(scope.row)"
                  >Дэлгэрэнгүй үзэх</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      v-if="driverConfig"
      :title="'Тохиргоо'"
      width="600px"
      :visible.sync="dialogVisible"
    >
      <el-form
        :model="driverConfig"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-form-item label="Радиус:">
          <el-input
            v-model="driverConfig.radius"
            placeholder="Радиус оруулна уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Хэмжээ:">
          <el-input
            v-model="driverConfig.size"
            placeholder="Хэмжээ оруулна уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Төрөл">
          <el-input
            v-model="driverConfig.rider_type"
            placeholder="Хэмжээ оруулна уу?"
          >
          </el-input>
        </el-form-item>

        <!-- <el-form-item label="Категори:" prop="dayOfName">
          <el-select
            v-model="selSuggestType.dayOfName"
            @change="$forceUpdate()"
            multiple
            placeholder="Англи нэрээ оруулна уу?"
          >
            <el-option label="Даваа" value="Mon"> </el-option>
            <el-option label="Мягмар" value="Tue"> </el-option>
            <el-option label="Лхагва" value="Wed"> </el-option>
            <el-option label="Пүрэв" value="Thu"> </el-option>
            <el-option label="Баасан" value="Fri"> </el-option>
            <el-option label="Бямба" value="Sat"> </el-option>
            <el-option label="Ням" value="Sun"> </el-option>
          </el-select>
        </el-form-item> -->

        <!-- <el-form-item label="Дуусах өдөр:" prop="end_date">
          <el-date-picker
            v-model="selSuggestType.end_date"
            type="date"
            @blur="$forceUpdate()"
            placeholder="Дуусах өдрөө сонгоно уу?"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Эхлэх цаг:" prop="start_time">
          <el-time-select
            v-model="selSuggestType.start_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            @blur="$forceUpdate()"
            placeholder="Эхлэх цагаа сонгоно уу?"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="Дуусах цаг:" prop="end_time">
          <el-time-select
            v-model="selSuggestType.end_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            @blur="$forceUpdate()"
            placeholder="Дуусах цагаа сонгоно уу?"
          >
          </el-time-select>
        </el-form-item> -->
      </el-form>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="success" @click="createOrUpdate">{{
          selSuggestType.id > 0 ? "Засах" : "Үүсгэх"
        }}</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
// import axios from "axios";
import service from "../helpers/services";
export default {
  name: "SuggestList",
  created() {
    this.getSuggest();
  },
  data() {
    return {
      dialogVisible: false,
      driverConfigList: [],
      driverConfig: null
    };
  },
  methods: {
    getSuggest() {
      service.getAllDriverConfig().then(res => {
        if (res.status === "success") {
          this.driverConfigList = res.data;
        }
      });
    },
    suggestDetail(data) {
      this.$router.push({
        path: "suggest-detail",
        query: {
          rider_type: data.rider_type
        }
      });
    }
    // edit(data){
    //   this.driverConfig = data
    //   this.dialogVisible = true
    // }
  }
};
</script>
<style>
.time {
  font-size: 13px;
  color: #777;
  margin-bottom: 15px;
}
.time1 {
  font-size: 10px;
  color: #999;
  margin-bottom: 15px;
}
.tag {
  float: left;
  margin-top: 5px;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>
